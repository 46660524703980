import React from "react";

import {Link} from "@inertiajs/react";

export default function NewsBlock({slug, imgURL, tags, date, title, text, locale}) {
    return (
        <div className="news-block">
            <Link href={`/${locale}/news/${slug}`} className="news-block__link">&nbsp;</Link>
            <div className="news-block__wrapper">
                <div className="news-block__preview">
                    <img src={`/storage/${imgURL}`} alt="Preview news card"/>
                </div>
                <div className="news-block__main-info">
                    <div className="news-block__service-info">
                        <div className="tags">
                            {
                                tags.map((tag) => {
                                    return <div key={tag.id} className="tag">
                                        <span>{tag.name}</span>
                                    </div>
                                })
                            }
                        </div>
                        <span className="news-block__date">{date}</span>
                    </div>
                    <h3 className="news-block__title mt-[11px]" dangerouslySetInnerHTML={{__html: title}}></h3>
                    <p className="news-block__description mt-25px" dangerouslySetInnerHTML={{__html: text}}></p>
                </div>
            </div>
        </div>
    );
}
